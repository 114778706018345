<template>
    <div>
    
        <b-modal ref="modal-add" size="xl" v-model="computedShow" no-close-on-backdrop :title="trans('settings-add-user',206)">
            <b-card>
                <app-view-table title="Suspects" :loading="loading" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields" :isSearch="true" >
                    <template v-slot:menu-items="cell">
                        <b-dropdown-item v-if="filters.list_type == 1" @click="registerUser(cell.data.item)">{{trans('settings-register-user',206)}}</b-dropdown-item>
                    </template> 
                </app-view-table>
            </b-card>
            <template #modal-footer>
                <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            </template>
        </b-modal>
    </div> 
</template>

<script>
import axios from "@axios";

export default {

    props: ["show"],

    created(){
        this.onCreateThis();
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

    },

    data() {
        return {
           
            loading: false,
            loading_data: false,

            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0,
            },

            items: {
                list: [],
                total: 0,
                refresh: 0
            },
            all_items: {
                list: [],
                total: 0
            },

            fields: [
                { key: "displayName", label: this.trans('settings-user-name',206), thStyle: { "min-width": "80px", width: "100%" }, visible: true},
                { key: "givenName", label: this.trans('fp-firstname',172), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true},
                { key: "surname", label: this.trans('last-name',182), thStyle: { "min-width": "180px", "max-width": "180px" }, visible: true},                
                { key: "mail", label: this.trans('email',182), thStyle: { "min-width": "280px", "max-width": "280px" }, visible: true},  
                { key: "mobilePhone", label: this.trans('phone-number',182), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true}, 
                { key: "role", label: this.trans('settings-roles',207), thStyle: { "min-width": "60px", "max-width": "60px" }, visible: true},          
            ],
        };
    },


    methods: {

       getList: function() {
             this.loading = true;
            axios
                .post("settings/users/getAzureUsers", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.all_items = res.data.items;

                    this.setLists();
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading = false;
                });
        },

        

        setLists(){
                var temp_list_not =  JSON.parse(JSON.stringify(this.all_items));
                
                if(this.filters.search.length > 0){
                    temp_list_not.list = temp_list_not.list.filter(x=> (x.displayName + x.mail + x.givenName + x.surname).toUpperCase().indexOf(this.filters.search.toUpperCase()) > -1)
                }
                
                if(this.filters.sorting.sort_direction == 2){
                    temp_list_not.list.sort((a,b) => 
                                    (a[this.filters.sorting.field_name] > b[this.filters.sorting.field_name]) ? 1 : 
                                    ((b[this.filters.sorting.field_name] > a[this.filters.sorting.field_name]) ? -1 : 0))
                }

                if(this.filters.sorting.sort_direction == 1){
                    temp_list_not.list.sort((a,b) => 
                                    (a[this.filters.sorting.field_name] < b[this.filters.sorting.field_name]) ? 1 : 
                                    ((b[this.filters.sorting.field_name] < a[this.filters.sorting.field_name]) ? -1 : 0))
                }
                if((this.filters.per_page * (this.filters.page-1))+1 > this.filters.total ) {
                    this.filters.page = 1;
                }

                this.items.list = temp_list_not.list.slice(this.filters.per_page * (this.filters.page - 1), (this.filters.per_page * (this.filters.page - 1)) + this.filters.per_page);
                this.items.total = temp_list_not.list.length;
                this.items.refresh = temp_list_not.refresh;

        },

        registerUser(item){
            this.$emit("update:return", item);
            this.$emit("update:result", true);
            this.$emit("update:show", false);
        },

        closeModal() {
            this.$emit("update:show", false);
        },

        
        onCreateThis() { 

            this.getList();


             this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.first_load = false;
                    this.setLists();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.first_load = false;
                    this.setLists();
                } 
            });

             this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading) {
                    this.setLists();
                }   
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading) {
                    this.setLists();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading) {
                    this.getList();
                }   
            });
        },

    },
};
</script>

<style scoped>
.app-local-card-size{
    min-height:300px !important; 
    max-height:600px !important
}
.app-local-icon{
    width:40px !important;
}
.app-local-dropdown{
    width:65px
}
.app-local-list-group-custom-style{
    max-height:300px !important;
    overflow-y: auto;
}
.app-local-list-group-item-custom-style{
    cursor:pointer; 
    padding:4px 4px;
}
.input-group-text {
    border: none;
}
.ps{
    height: 350px;
}
.card-body {
    padding-top: 0.5rem;
}
</style>
<style>

.custom-legend-size .col-md-4{
    flex: 0 0 28% !important;
}
</style>
