<template>
    <div>
        <b-card>
            <app-view-table :title="trans('users',167)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" v-on:onRowSelected="getProfile" :fields="fields" :filterOptions="user_status_list" :isFilterOptions="true" :isSearch="true" :isDropdownMenu="user_role == 1" class="table-selectable" :customColumns="custom_columns">
                <template v-slot:menu-items="cell">
                     <b-dropdown-item v-if="filters.list_type == 1" @click="blockUser(cell.data.item.id_user)">{{trans('block',175)}}</b-dropdown-item>
                     <b-dropdown-item v-if="filters.list_type == 2" @click="unblockUser(cell.data.item.id_user)">{{trans('unblock',175)}}</b-dropdown-item>
                </template> 
                <template v-slot:buttons>
                    <app-button type="primary" @click="show_azure_list = true">{{trans('settings-add-user',206)}}</app-button>
                </template>
                <template v-slot:id_easyflex="cell">
                    <span v-bind:class="{ dotActive: cell.data.item.id_easyflex != null, dotInactive: cell.data.item.id_easyflex == null }" data-toggle="tooltip" :title="cell.data.item.id_easyflex"> </span>
                </template>
                <template v-slot:azure_user_id="cell">
                    <span v-bind:class="{ dotActive: cell.data.item.azure_user_id != null, dotInactive: cell.data.item.azure_user_id == null }" data-toggle="tooltip" :title="cell.data.item.azure_user_id"> </span>
                </template>

            </app-view-table>
        </b-card>  

        <settings-users-add v-if="show_azure_list"  :show.sync="show_azure_list" :return.sync="register_user" :result.sync="show_user_office"/>
        <settings-users-profile-offices-change v-if="show_user_office" :show.sync="show_user_office" :args="register_user" source="user_list" />
    </div>
</template>
<script>


import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import SettingsUsersAdd from './SettingsUsersAdd.vue'
import SettingsUsersProfileOfficesChange from '@app/Settings/Users/Profile/Locations/SettingsUsersProfileOfficesChange.vue';
export default {

    components: {
        SettingsUsersAdd,
        SettingsUsersProfileOfficesChange
        
    },
    mounted() {
        this.onCreateThis();
    },

    data() {
        return {

            loading_list: false,
            show_azure_list: false,
            show_user_office: false,
            register_user: null,

            user_status_list: [
                { value: 1, name: this.trans('active',3) },
                { value: 2, name: this.trans('blocked',3) },
            ],
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0,
            },
            alertClass: new AppAlerts(),
            fields: [
                { key: "user_name", label: this.trans('full-name',3), thStyle: { "min-width": "80px", width: "100%" }, visible: true},
                { key: "id_easyflex", label: 'EF', thStyle: { "min-width": "55px", "max-width": "55px" }, visible: true, sortable: false}, 
                { key: "azure_user_id", label: 'AZ', thStyle: { "min-width": "55px", "max-width": "55px" }, visible: true, sortable: false}, 
                { key: "role", label: this.trans('settings-role',207), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true},                
                { key: "email", label: this.trans('email',182), thStyle: { "min-width": "350px", "max-width": "350px" }, visible: true},  
                { key: "last_event_date", label: this.trans('settings-last-event-date',207), thStyle: { "min-width": "220px", "max-width": "220px" }, visible: true},              
            ],
            items: [],
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            custom_columns: ['id_easyflex', 'azure_user_id']
        };
    },


    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/users/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
                
        },

        getProfile: function(items) {
            this.$router.push({ name: "users-profile", params: { id_user: items[0].id_user } });
        },

        blockUser: function(id_user) {
            this.alertClass.openAlertConfirmation(this.trans('settings-continue-confirmation',167)).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post("settings/users/block", {
                            id_user : id_user,
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading_list = false;
                        });
                }
            });
                
        },

         unblockUser: function(id_user) {
            this.alertClass.openAlertConfirmation(this.trans('settings-continue-confirmation',167)).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post("settings/users/unblock", {
                            id_user : id_user,
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading_list = false;
                        });
                }
            });
                
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.list_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        },
    }
 
};
</script>
<style scoped>
.dotActive {
  height: 10px;
  width: 10px;
  margin-left: 4px;
  background-color: #20dc37;
  border-radius: 50%;
  display: inline-block;
}
.dotInactive {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  margin-left: 4px;
  display: inline-block;
}
</style>